<template>
  <div class="app-container">
    <div class="searchForm_box">
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-input
            placeholder="請輸入內容"
            v-model="searchForm.searchTxt"
            class="input-with-select"
          >
            <el-select
              v-model="searchForm.searchChoose"
              slot="prepend"
              placeholder="請選擇"
              style="width: 130px"
            >
              <el-option label="店鋪ID" value="shop_id"></el-option>
              <el-option label="店鋪名稱" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item label="代理商">
          <el-select
            v-model="searchForm.agent_id"
            filterable
            placeholder="請選擇"
          >
            <el-option
              v-for="item in agentOptions"
              :key="item.agent_id"
              :label="item.agent_name"
              :value="item.agent_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="addShop"
            >新增
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 表格 -->
    <el-table
      v-loading="tableLoading"
      :data="shopList"
      :border="true"
      class="table"
      align="center"
    >
      <el-table-column
        label="店鋪ID"
        width="80"
        prop="shop_id"
        align="center"
      ></el-table-column>
      <el-table-column
        label="代理商"
        prop="agent.agent_name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="店鋪名稱"
        prop="shop_name"
        align="center"
      ></el-table-column>
      <!-- <el-table-column label="進店二維碼" prop="qrcode_url" align="center">
        <template slot-scope="scope">
          <el-link
            v-if="scope.row.qrcode_url == '' || scope.row.qrcode_url == null"
            type="primary"
            @click="createQrcode(scope.row.shop_id)"
            >點擊生成二維碼</el-link
          > -->
      <!--                    <el-popover-->
      <!--                            placement="right"-->
      <!--                            width="300"-->
      <!--                            trigger="click">-->
      <!--                        <img v-if="scope.row.qrcode_url" :src=" scope.row.qrcode_url" width="500"/>-->

      <!--                        <img v-if="scope.row.qrcode_url" slot="reference"> :src=" scope.row.qrcode_url" width="200"/>-->
      <!--                        <el-button slot="reference">click 激活</el-button>-->
      <!--                    </el-popover>-->

      <!-- <el-popover
            v-if="scope.row.qrcode_url"
            placement="top-start"
            trigger="click"
          > -->
      <!--trigger屬性值：hover、click、focus 和 manual-->
      <!-- <a
              :href="scope.row.qrcode_url"
              target="_blank"
              title="查看最大化圖片"
            >
              <img
                :src="scope.row.qrcode_url"
                style="width: 400px; height: 400px"
              />
            </a>
            <img
              slot="reference"
              :src="scope.row.qrcode_url"
              style="width: 50px; height: 50px; cursor: pointer"
            />
          </el-popover>
        </template>
      </el-table-column> -->
      <!--            <el-table-column label="店鋪logo" prop="shop_logo" align="center" width="150">-->
      <!--                <template slot-scope="scope">-->
      <!--                    <div style="-->
      <!--              width: 50px;-->
      <!--              height: 50px;-->
      <!--              text-align: center;-->
      <!--              margin: auto;-->
      <!--              overflow: hidden;-->
      <!--            ">-->
      <!--                        <img :src=" scope.row.shop_logo != '' ? scope.row.shop_logo : require('@/assets/image/default.jpg') "-->
      <!--                             width="50"/>-->
      <!--                    </div>-->
      <!--                </template>-->
      <!--            </el-table-column>-->
      <el-table-column
        label="微耕版號"
        prop="wg_sn"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <!-- <el-table-column
        label="店鋪類型"
        prop="shop_type"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag
            style="padding: 0px 4px; line-height: 20px; height: 20px"
            type="success"
          >
            {{
              scope.row.shop_type == 1
                ? "RFID"
                : scope.row.shop_type == 2
                ? "磁標"
                : scope.row.shop_type == 3
                ? "雲值守"
                : ""
            }}
          </el-tag>
        </template>
      </el-table-column> -->
      <el-table-column label="支付方式" prop="pay_way" align="center">
        <template slot-scope="scope">
          <el-tag
            style="padding: 0px 4px; line-height: 20px; height: 20px"
            type="success"
          >
            <!-- {{
              scope.row.pay_way == 1
                ? "官方支付"
                : scope.row.pay_way == 2
                ? "杉德支付"
                : scope.row.pay_way == 3
                ? "銀聯支付"
                : ""
            }} -->
            {{ scope.row.pay_way == 2 ? "聚合支付" : "暫無信息" }}
          </el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="是否開啟雲值守"
        prop="cloud_watch_status"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.cloud_watch_status == '1'"
            style="padding: 0px 4px; line-height: 20px; height: 20px"
            type="success"
            >開啟</el-tag
          >
          <el-tag
            v-if="scope.row.cloud_watch_status == '0'"
            style="padding: 0px 4px; line-height: 20px; height: 20px"
            type="danger"
            >關閉</el-tag
          > -->
      <!--                    <el-tag style="padding:0px 4px;line-height: 20px;height: 20px" type="success">{{-->
      <!--                    scope.row.cloud_watch_status == "1" ? "開啟" : "關閉"-->
      <!--                  }}</el-tag>-->
      <!-- </template>
      </el-table-column> -->
      <el-table-column label="店鋪狀態" prop="status" align="center">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.status == '1'"
            style="padding: 0px 4px; line-height: 20px; height: 20px"
            type="danger"
            >待審核</el-tag
          >
          <el-tag
            v-if="scope.row.status == '2'"
            style="padding: 0px 4px; line-height: 20px; height: 20px"
            type="success"
            >正常營業</el-tag
          >
          <el-tag
            v-if="scope.row.status == '3'"
            style="padding: 0px 4px; line-height: 20px; height: 20px"
            type="danger"
            >審核駁回</el-tag
          >
          <el-tag
            v-if="scope.row.status == '4'"
            style="padding: 0px 4px; line-height: 20px; height: 20px"
            type="danger"
            >關店</el-tag
          >
          <!--                    <el-tag style="padding:0px 4px;line-height: 20px;height: 20px" type="success">{{-->
          <!--            scope.row.status == "1"-->
          <!--              ? "待審核"-->
          <!--              : scope.row.status == "2"-->
          <!--              ? "正常營業"-->
          <!--              : scope.row.status == "3"-->
          <!--              ? "審核駁回"-->
          <!--              : scope.row.status == "4"-->
          <!--              ? "關店"-->
          <!--              : ""-->
          <!--          }}</el-tag>-->
        </template>
      </el-table-column>
      <el-table-column label="創建時間" align="center">
        <template slot-scope="scope">
          {{ scope.row.create_time | setDate }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160" align="center">
        <template slot-scope="scope">
          <el-button
            style="color: #409eff !important"
            size="small"
            type="text"
            icon="el-icon-edit"
            @click="editShop(scope.row.shop_id)"
            >編輯
          </el-button>
          <el-button
            style="color: #409eff !important"
            size="small"
            type="text"
            icon="el-icon-s-check"
            @click="editStatus(scope.row)"
            >審核
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分頁 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 添加/編輯 -->
    <el-dialog
      :title="shopTitle + '店鋪'"
      :visible.sync="shopVisible"
      :before-close="handelClose"
      width="600px"
    >
      <el-form
        :model="shopForm"
        :rules="shopFormRules"
        ref="shopForm"
        label-width="130px"
        class="demo-ruleForm"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="代理商">
              <treeselect
                v-model="shopForm.agent_id"
                :options="agentOptions"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="選擇代理商"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="shopTitle == '添加'">
            <el-form-item label="店鋪狀態" prop="status">
              <el-radio-group v-model="shopForm.status">
                <el-radio :label="1">待審核</el-radio>
                <el-radio :label="2">審核通過</el-radio>
                <el-radio :label="3">審核不通過</el-radio>
                <el-radio :label="4">關店</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="店鋪名稱" prop="shop_name">
              <el-input
                v-model="shopForm.shop_name"
                placeholder="請輸入店鋪名稱"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="店鋪聯繫人" prop="shop_person">
              <el-input
                v-model="shopForm.shop_person"
                placeholder="請輸入店鋪聯繫人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="店鋪地址" prop="shop_address">
              <el-input
                v-model="shopForm.shop_address"
                placeholder="請輸入店鋪地址"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="24">
            <el-form-item label="店鋪坐標">
              <el-input
                style="width: 30%"
                v-model="shopForm.lng"
                placeholder="經度"
              ></el-input>
              <el-input
                style="width: 30%; margin: 0 20px"
                v-model="shopForm.lat"
                placeholder="維度"
              ></el-input>
              <el-button
                @click="showMap = !showMap"
                type="primary"
                size="small"
                icon="el-icon-location-information"
                >{{ showMap ? " 關閉坐標拾取" : " 坐標拾取" }}</el-button
              >
            </el-form-item>
            <getPoint v-if="showMap"></getPoint>
          </el-col> -->

          <!-- <el-col :span="24">
            <el-form-item label="店鋪類型" prop="shop_type">
              <el-radio-group v-model="shopForm.shop_type">
                <el-radio :label="1">RFID</el-radio>
                <el-radio :label="2">磁標</el-radio>
                <el-radio :label="3">雲值守</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="收銀機歡迎頁" prop="shop_logo">
              <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :auto-upload="false"
                :on-change="updateImg"
              >
                <img
                  v-if="shopForm.shop_logo"
                  :src="shopForm.shop_logo"
                  class="avatar"
                  width="150"
                  height="150"
                />
                <!-- <div v-else class="avatar-uploader-icon">
                  <i class="el-icon-plus"></i>
                  <p>上傳圖片</p>
                </div> -->
                <div v-else>
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <div style="text-align: center" slot="tip">上傳圖片</div>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="人臉識別機號" prop="face_sn">
              <el-input
                v-model="shopForm.face_sn"
                placeholder="請輸入人臉識別機設備號"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="微耕版號" prop="wg_sn">
              <el-input
                v-model="shopForm.wg_sn"
                placeholder="請輸入微耕版號"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="是否快速開門" prop="is_fast">
              <el-radio-group v-model="shopForm.is_fast">
                <el-radio :label="1">開啟</el-radio>
                <el-radio :label="0">關閉</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12">
            <el-form-item label="是否使用掃碼購" prop="is_scan">
              <el-radio-group v-model="shopForm.is_scan">
                <el-radio :label="1">開啟</el-radio>
                <el-radio :label="0">關閉</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12">
            <el-form-item label="螢石SN" prop="ys_sn">
              <el-input
                v-model="shopForm.ys_sn"
                placeholder="請輸入螢石SN"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="24">
            <el-form-item label="螢石key" prop="ys_appkey">
              <el-input
                v-model="shopForm.ys_appkey"
                placeholder="請輸入螢石key"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="24">
            <el-form-item label="螢石secret" prop="ys_secret">
              <el-input
                v-model="shopForm.ys_secret"
                placeholder="請輸入螢石secret"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="24">
            <el-form-item label="進店二維碼地址" prop="qrcode_url">
              <el-input
                v-model="shopForm.qrcode_url"
                placeholder="請輸入二維碼地址"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="24">
            <el-form-item label="微信結算商戶號" prop="sub_mch_id">
              <el-input
                v-model="shopForm.sub_mch_id"
                placeholder="請輸入結算商戶號"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12">
            <el-form-item label="支付寶PID" prop="alipay_pid">
              <el-input
                v-model="shopForm.alipay_pid"
                placeholder="請輸入支付寶PID"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12">
            <el-form-item label="支付寶三方token" prop="app_auth_token">
              <el-input
                v-model="shopForm.app_auth_token"
                placeholder="請輸入支付寶三方token"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="支付方式" prop="pay_way">
              <el-radio-group v-model="shopForm.pay_way">
                <!-- <el-radio :label="1">微信支付寶官方</el-radio> -->
                <el-radio :label="2">聚合支付</el-radio>
                <!-- <el-radio :label="3">拉卡拉支付</el-radio> -->
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="杉德商戶號" prop="sand_number">
              <el-input
                v-model="shopForm.sand_number"
                placeholder="請輸入杉德商戶號"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="商戶號" prop="merchant_no">
              <el-input
                v-model="shopForm.merchant_no"
                placeholder="請輸入商戶號"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="終端號" prop="term_no">
              <el-input
                v-model="shopForm.term_no"
                placeholder="請輸入終端號"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收銀機賬號" prop="shop_phone">
              <el-input
                v-model="shopForm.shop_phone"
                placeholder="請輸入收銀機賬號"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="服務費率">
              <el-input
                v-model="shopForm.rate"
                placeholder="請輸入服務費率"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服務費封頂">
              <el-input
                v-model="shopForm.service_fee"
                placeholder="請輸入服務費封頂值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否開啟雲值守" prop="cloud_watch_status">
              <el-radio-group v-model="shopForm.cloud_watch_status">
                <el-radio :label="1">開啟</el-radio>
                <el-radio :label="0">關閉</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否開啟一鍵鎖門" prop="shop_lock">
              <el-radio-group v-model="shopForm.shop_lock">
                <el-radio :label="1">開啟</el-radio>
                <el-radio :label="0">關閉</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="是否開啟視頻彈窗">
              <el-radio-group v-model="shopForm.is_video">
                <el-radio :label="1">開啟</el-radio>
                <el-radio :label="0">關閉</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="備註" prop="remark">
              <el-input
                v-model="shopForm.remark"
                placeholder="請填寫備註"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="shopConfirm"
          >提交</el-button
        >
        <el-button size="small" @click="shopReset">重置</el-button>
      </span>
    </el-dialog>
    <!-- 審核 -->
    <el-dialog :visible.sync="editStatusVisible" title="審核" width="520px">
      <el-form
        size="small"
        :model="statusForm"
        ref="statusForm"
        :rules="statusFormRule"
        label-width="80px"
      >
        <el-form-item label="審核狀態">
          <el-radio-group v-model="statusForm.status">
            <el-radio :label="1">待審核</el-radio>
            <el-radio :label="2">審核通過</el-radio>
            <el-radio :label="3">審核不通過</el-radio>
            <el-radio :label="4">關店</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="原因"
          v-if="statusForm.status == 3"
          prop="status_msg"
        >
          <el-input
            v-model="statusForm.status_msg"
            placeholder="請輸入拒絕原因"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="statusConfirm"
          >確定</el-button
        >
        <el-button size="small" @click="editStatusVisible = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { compressImg, dataURLtoFile } from "../../components/common/pic";
import getPoint from "./components/getPoint.vue";

export default {
  name: "shopManage",
  components: { Treeselect, getPoint },
  data() {
    return {
      searchForm: {
        searchTxt: "",
        searchChoose: "shop_name",
        shop_id: "",
        shop_name: "",
        agent_id: "",
      },
      tableLoading: false,
      page: 1,
      pageSize: 50,
      total: 0,
      shopList: [],
      // 添加
      shopTitle: "添加",
      shopVisible: false,
      shopForm: {
        agent_id: null,
        status: 2,
        pay_way: 2,
      },
      shopFormRules: {
        shop_name: [
          { required: true, message: "請輸入店鋪名稱", trigger: "blur" },
        ],
        shop_address: [
          { required: true, message: "請輸入店鋪地址", trigger: "blur" },
        ],
        wg_sn: [{ required: true, message: "請輸入微耕版號", trigger: "blur" }],
        face_sn: [
          { required: true, message: "請輸入人臉識別設備號", trigger: "blur" },
        ],
        ys_sn: [{ required: true, message: "請輸入螢石SN", trigger: "blur" }],
        ys_appkey: [
          { required: true, message: "請輸入螢石key", trigger: "blur" },
        ],
        ys_secret: [
          { required: true, message: "請輸入螢石secret", trigger: "blur" },
        ],
        shop_phone: [
          { required: true, message: "請輸入收銀機賬號", trigger: "blur" },
        ],
      },
      // 代理商選擇
      agentOptions: [],
      normalizer(node) {
        if (node.children && !node.children.length) {
          delete node.children;
        }
        return {
          id: node.agent_id,
          label: node.agent_name,
          children: node.children,
        };
      },
      upLoading: "",
      // 審核
      editStatusVisible: false,
      statusForm: {
        shop_id: "",
        status: null,
        status_msg: "",
      },
      statusFormRule: {
        status_msg: [
          { required: true, message: "請輸入拒絕原因", trigger: "blur" },
        ],
      },
      restaurants: [],
      showMap: false,
    };
  },
  mounted() {
    this.getShopList();
    this.getAgentList();
  },
  methods: {
    handleSelect(item) {
      console.log(item);
    },
    formatStr(str) {
      str.substr(0, 10);
    },
    //搜索
    handleQuery() {
      this.page = 1;
      this.getShopList();
    },
    // 獲取店鋪列表
    getShopList() {
      let params = {
        page: this.page,
        pageSize: this.pageSize,
        agent_id: this.searchForm.agent_id,
        shop_id:
          this.searchForm.searchChoose == "shop_id"
            ? this.searchForm.searchTxt
            : "",
        shop_name:
          this.searchForm.searchChoose == "shop_name"
            ? this.searchForm.searchTxt
            : "",
      };
      this.tableLoading = true;
      this.$api.getShop(params).then((response) => {
        this.shopList = response.data.list;
        this.total = response.data.count;
        this.tableLoading = false;
      });
    },
    // 分頁
    handleSizeChange(val) {
      this.pageSize = val;
      this.getShopList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getShopList();
    },
    // 獲取代理商選項
    getAgentList() {
      this.agentOptions = [];
      this.$api
        .agentList({
          page: 1,
          pageSize: 99999,
          is_tree: 1,
        })
        .then((res) => {
          this.agentOptions = res.data.list;
        });
    },
    // 彈出層關閉並重置表單
    handelClose() {
      this.shopVisible = false;
      this.$refs.shopForm.resetFields();
      this.shopReset();
      this.showMap = false;
    },
    // 重置表單內容
    shopReset() {
      this.shopForm = {
        agent_id: null,
        shop_name: "",
        status: 2,
        shop_address: "",
        wg_sn: "",
        shop_type: 1,
        shop_logo: "",
        qrcode_icon: "",
        face_sn: "",
        is_fast: 1,
        is_scan: 1,
        ys_appkey: "",
        ys_secret: "",
        ys_sn: "",
        qrcode_url: "",
        sub_mch_id: "",
        alipay_pid: "",
        app_auth_token: "",
        pay_way: 2,
        sand_number: "",
        merchant_no: "",
        term_no: "",
        shop_person: "",
        shop_phone: "",
        cloud_watch_status: 1,
        shop_lock: 1,
        remark: "",
        service_fee: "",
        rate: "",
        is_video: "",
        lng: "",
        lat: "",
      };
    },
    addShop() {
      this.shopVisible = true;
      this.shopTitle = "添加";
      // this.shopReset();
      this.getAgentList();
    },
    // 編輯
    editShop(id) {
      this.shopTitle = "編輯";
      //   this.shopReset();
      this.getAgentList();
      this.$api.shopDetail({ shop_id: id }).then((res) => {
        if (res.code == 200) {
          this.shopVisible = true;
          this.shopForm = res.data;
        }
      });
    },
    //生成二維碼
    createQrcode(shop_id) {
      this.$http(
        "/admin/shop/getQrCode",
        {
          shop_id: shop_id,
        },
        "post"
      ).then((res) => {
        this.getShopList();
      });
    },
    shopConfirm() {
      this.$refs["shopForm"].validate((valid) => {
        if (valid) {
          // const loading = this.$loading({
          //     text: "正在提交...",
          //     background: "rgba(255,255,255,0.5)",
          // });
          if (
            this.shopForm.shop_id != null &&
            this.shopForm.shop_id != undefined
          ) {
            this.bd09togcj02(this.shopForm.lng, this.shopForm.lat);
            const params = {
              shop_id: this.shopForm.shop_id,
              agent_id: this.shopForm.agent_id,
              shop_name: this.shopForm.shop_name,
              shop_address: this.shopForm.shop_address,
              wg_sn: this.shopForm.wg_sn,
              shop_type: this.shopForm.shop_type,
              shop_logo: this.shopForm.shop_logo,
              qrcode_icon: this.shopForm.qrcode_icon,
              face_sn: this.shopForm.face_sn,
              is_fast: this.shopForm.is_fast,
              is_scan: this.shopForm.is_scan,
              ys_appkey: this.shopForm.ys_appkey,
              ys_secret: this.shopForm.ys_secret,
              ys_sn: this.shopForm.ys_sn,
              qrcode_url: this.shopForm.qrcode_url,
              sub_mch_id: this.shopForm.sub_mch_id,
              alipay_pid: this.shopForm.alipay_pid,
              app_auth_token: this.shopForm.app_auth_token,
              pay_way: this.shopForm.pay_way,
              sand_number: this.shopForm.sand_number,
              merchant_no: this.shopForm.merchant_no,
              term_no: this.shopForm.term_no,
              shop_person: this.shopForm.shop_person,
              shop_phone: this.shopForm.shop_phone,
              cloud_watch_status: this.shopForm.cloud_watch_status,
              shop_lock: this.shopForm.shop_lock,
              remark: this.shopForm.remark,
              service_fee: this.shopForm.service_fee,
              rate: this.shopForm.rate,
              is_video: this.shopForm.is_video,
              lng: this.shopForm.lng,
              lat: this.shopForm.lat,
            };
            this.$api.editShop(params).then((res) => {
              this.$message.success("修改成功");
              this.getShopList();
              //   this.shopVisible = false;
              this.handelClose();
            });
          } else {
            this.bd09togcj02(this.shopForm.lng, this.shopForm.lat);
            this.$api.addShop(this.shopForm).then((res) => {
              this.$message.success("添加成功");
              //   this.shopVisible = false;
              this.handelClose();
              this.getShopList();
            });
          }
        }
      });
    },
    // 圖片上傳
    updateImg(file) {
      this.upLoading = this.$loading({
        text: "正在上傳...",
        background: "rgba(255,255,255,0.5)",
      });
      if (file.size > 1024) {
        console.log("111");
        compressImg(file).then((data) => {
          var aFile = dataURLtoFile(data, file.name);
          this.fileUpload(aFile);
        });
      } else {
        this.fileUpload(file.raw);
      }
    },
    fileUpload(file) {
      this.$api.uploadImage(file).then((res) => {
        this.upLoading.close();
        if (res.data.code == 200) {
          this.shopForm.shop_logo = res.data.data.url;
          this.$message.success("上傳成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 審核
    editStatus(row) {
      this.editStatusVisible = true;
      this.statusForm.shop_id = row.shop_id;
      this.statusForm.status = row.status;
    },
    statusConfirm() {
      this.$refs["statusForm"].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            text: "正在提交審核...",
            background: "rgba(255,255,255,0.5)",
          });
          if (this.statusForm.status != 3) {
            this.statusForm.status_msg = "";
          }
          this.$api.editStatus(this.statusForm).then((res) => {
            loading.close();
            if (res.code == 200) {
              this.$message.success("審核提交成功");
              this.editStatusVisible = false;
              this.getShopList();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    // 百度坐标转换gcj_02坐标
    bd09togcj02(bd_lon, bd_lat) {
      var x_pi = (3.14159265358979324 * 3000.0) / 180.0;
      var x = bd_lon - 0.0065;
      var y = bd_lat - 0.006;
      var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
      var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
      var gg_lng = z * Math.cos(theta);
      var gg_lat = z * Math.sin(theta);
      this.shopForm.lng = gg_lng;
      this.shopForm.lat = gg_lat;
    },
  },
};
</script>

<style lang="scss" scoped>
// .thestoremenu {
//   .users_form {
//     padding: 5px 0px;
//     display: flex;
//     align-items: center;

//     .el-form {
//       width: 300px;
//       height: 40px;
//     }

//     .el-button {
//       margin: 0px 10px;
//     }
//   }
// }
.avatar-uploader {
  width: 150px;
  height: 150px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  // position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.avatar-uploader:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  color: #8c939d;
  // width: 150px;
  // height: 150px;
  text-align: center;
  // padding: 40px 0 0 0;
  box-sizing: border-box;
  // line-height: 150px;
  font-size: 28px;
}

.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
</style>
