<template>
  <div>
    <div class="bdMap">
      <div class="Blocking_Baidu_logo"></div>
      <iframe :src="bdUrl" frameborder="1" width="100%" height="100%"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "getPoint",
  data() {
    return {
      bdUrl: "http://api.map.baidu.com/lbsapi/getpoint/index.html",
    };
  },
};
</script>

<style scoped lang="scss">
.bdMap {
  position: relative;
  width: 1200px;
  height: 800px;
  background-color: #e5e5e5;

  .Blocking_Baidu_logo {
    position: absolute;
    width: 150px;
    height: 100px;
    background-color: #e5e5e5;
    left: 20px;
    top: 2px;
  }
}
</style>
